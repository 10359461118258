<template>
  <div class="bulk-actions-wrapper">
    <a-checkbox v-model="check" :indeterminate="indeterminate">
      <span class="text-checkbox">{{ text }}</span>
    </a-checkbox>

    <span v-if="selectedCount > 0" class="vc-line">|</span>

    <div v-if="selectedCount > 0" class="icon-space">
      <a-popover placement="top" trigger="hover" overlayClassName="bulk-action">
        <template #content>
          <span class="text">Mark as read</span>
        </template>
        <FeatherIcon
          :type="'book-open'"
          :size="20"
          class="actions mark-as-read cursor-pointer"
          @click="markAsRead"
        />
      </a-popover>
      <a-popover
        v-if="role === 'domadmin' || role === 'admin'"
        placement="top"
        trigger="hover"
        overlayClassName="bulk-action"
        @visibleChange="showSentimentAction = false"
      >
        <template #content>
          <div v-if="showSentimentAction" class="icon-space sentiment">
            <FeatherIcon
              :type="'frown'"
              :size="20"
              class="negative cursor-pointer"
              @click="setSentiment('negative')"
            />
            <FeatherIcon
              :type="'meh'"
              :size="20"
              class="neutral cursor-pointer"
              @click="setSentiment('neutral')"
            />
            <FeatherIcon
              :type="'smile'"
              :size="20"
              class="positive cursor-pointer"
              @click="setSentiment('positive')"
            />
          </div>
          <span v-else class="text">Set sentiment</span>
        </template>
        <FeatherIcon
          :type="'smile'"
          :size="20"
          class="actions set-sentiment cursor-pointer"
          @click="showSentimentAction = true"
        />
      </a-popover>
      <a-popover
        v-if="role === 'domadmin'"
        placement="top"
        trigger="hover"
        overlayClassName="bulk-action"
      >
        <template #content>
          <span class="text">Reject</span>
        </template>
        <FeatherIcon
          :type="'slash'"
          :size="20"
          class="actions reject cursor-pointer"
          @click="reject"
        />
      </a-popover>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'BulkActions',
  props: {
    indeterminate: { type: Boolean, default: false },
    modelValue: { type: Boolean, default: false },
    selectedCount: { type: Number, default: 0 },
  },
  emits: ['onMarkAsRead', 'onSetSentiment', 'onReject'],
  setup(props, { emit }) {
    const store = useStore();
    const role = computed(() => store.getters['account/userRole']);
    const showSentimentAction = ref(false);
    const openSentiment = ref(false);
    const check = ref(false);
    const { selectedCount } = toRefs(props);
    const text = computed(() => {
      return selectedCount.value
        ? `${selectedCount.value} Selected`
        : 'Select All';
    });

    watch(openSentiment.value, (v) => {
      if (!v) {
        showSentimentAction.value = false;
      }
    });

    watch(check.value, () => {
      emit('update:modelValue', check.value);
    });

    const markAsRead = () => {
      emit('onMarkAsRead');
    };

    const setSentiment = (sentiment) => {
      emit('onSetSentiment', sentiment);
    };
    const reject = () => {
      emit('onReject');
    };
    return {
      role,
      showSentimentAction,
      openSentiment,
      text,
      check,
      markAsRead,
      setSentiment,
      reject,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '../../config/theme/colors.json';
.popover {
  z-index: 999;
}
.text-checkbox {
  color: rgba(90, 95, 125, 1);
}
:deep(.ant-checkbox + span) {
  padding-right: 0;
}
.bulk-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.icon-space {
  display: flex;
  gap: 0.75rem;
}

.vc-line {
  font-size: 14px;
  color: rgba(227, 230, 239, 1);
}

.sentiment {
  .positive {
    color: $success-color;
  }
  .neutral {
    color: #febc3c;
  }
  .negative {
    color: $danger-color;
  }
}
.actions {
  color: rgba(90, 95, 125, 1);
  padding: 8px;
  border-radius: 18px;
  width: 2.25rem;
  height: 2.25rem;
  &.mark-as-read {
    &:hover {
      background-color: rgba(81, 54, 217, 0.05);
      color: rgba(81, 54, 217, 1);
      fill: rgba(81, 54, 217, 1);
    }
  }
  &.set-sentiment {
    &:hover {
      background-color: rgba(51, 113, 255, 0.05);
      color: rgba(51, 113, 255, 1);
      fill: rgba(51, 113, 255, 1);
    }
  }
  &.reject {
    &:hover {
      background-color: rgba(255, 77, 79, 0.05);
      color: rgba(255, 77, 79, 1);
      fill: rgba(255, 77, 79, 1);
    }
  }
}
.text {
  color: #272b41;
}
</style>

<style>
.bulk-action .ant-popover-inner {
  padding: unset;
  z-index: 999;
}
</style>
