<template>
  <div class="sort-by-wrapper">
    <span v-if="!hideTitle" class="pale-gray">Sort by:&nbsp;</span>
    <a-dropdown v-model:visible="visible" :trigger="['click']" :get-popup-container="(trigger) => trigger.parentNode">
      <a-button class="sort-toggle" @click.stop="">
        <span class="selected-name">{{ selectedSortName }}</span>
        <FeatherIcon color="#868EAE" type="chevron-down" />
      </a-button>
      <template #overlay>
        <div class="sort-content-wrapper">
          <div class="menu-title">SORT BY</div>
          <a-menu mode="inline">
            <a-menu-item
              v-for="t in sortTypeList"
              :key="t.type"
              :class="{ active: t.type === sortType }"
              @click="onSelectSortType(t.type)"
            >
              {{ t.name }}
            </a-menu-item>
          </a-menu>
          <a-divider />
          <div class="menu-title">ORDER</div>
          <a-menu mode="inline">
            <a-menu-item key="asc" :class="{ active: direction === 'asc' }" @click="onSelectSortDirection('asc')">
              Ascending
            </a-menu-item>
            <a-menu-item key="desc" :class="{ active: direction === 'desc' }" @click="onSelectSortDirection('desc')">
              Descending
            </a-menu-item>
          </a-menu>
        </div>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import { toRefs, ref, computed } from 'vue';

const sortTypeList = [
  { type: 'created_at', name: 'Time' },
  { type: 'engagement_score', name: 'Engagement' },
  { type: 'search_score', name: 'Best Matches' },
  { type: 'like_count', name: 'Like/Reaction count' },
  { type: 'comment_count', name: 'Comment count' },
  { type: 'share_count', name: 'Share count' },
  { type: 'view_count', name: 'View count' },
];

export default {
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
    defaultSort: {
      type: String,
      default: 'engagement_score',
    },
    defaultDirection: {
      type: String,
      default: 'desc',
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { defaultSort, defaultDirection } = toRefs(props);
    const visible = ref(false);
    const sortType = ref(defaultSort.value);
    const direction = ref(defaultDirection.value);

    const emitAndClose = () => {
      emit('change', {
        type: sortType.value,
        direction: direction.value,
      });
      visible.value = false;
    };

    const onSelectSortType = (e) => {
      sortType.value = e;
      emitAndClose();
    };

    const onSelectSortDirection = (e) => {
      direction.value = e;
      emitAndClose();
    };

    const selectedSortName = computed(() => {
      if (sortType.value) {
        for (let i in sortTypeList) {
          if (sortTypeList[i].type === sortType.value) {
            return sortTypeList[i].name;
          }
        }
      }
      return 'Best matches';
    });

    return {
      visible,
      sortType,
      sortTypeList: sortTypeList,
      direction,
      onSelectSortType,
      onSelectSortDirection,
      selectedSortName,
    };
  },
};
</script>

<style lang="scss">
.sort-by-wrapper {
  display: flex;
  align-items: center;
  .sort-toggle {
    height: 36px;
    .selected-name {
      color: #5a5f7d !important;
    }
  }
  .sort-content-wrapper {
    .menu-title {
      padding: 10px 12px 0 12px;
      color: #9299b8;
      font-size: 11px;
      line-height: 14px;
    }
    .ant-menu-item {
      padding-left: 12px !important;
      margin-bottom: 0 !important;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: #5a5f7d;
      &.active {
        color: #3371ff;
        background: #f1f7ff;
      }
    }
    .ant-divider {
      margin: 10px 0 0 0;
    }
  }
}
</style>
