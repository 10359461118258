<template>
  <div class="card-export" :class="{ 'card-export-close': !isExpand }">
    <div class="header">
      <div class="header-left">
        <img :src="require('@/assets/images/export/export-file.png')" />
        <div class="title-block">
          <span class="title">Export File</span>
          <span class="desc"> {{ getDesc }}</span>
        </div>
      </div>
      <div class="header-right">
        <FeatherIcon
          v-if="isExpand"
          type="chevron-down"
          size="18"
          style="margin-right: 0.5rem"
          @click="isExpand = !isExpand"
        />
        <FeatherIcon
          v-if="!isExpand"
          type="chevron-up"
          size="18"
          style="margin-right: 1rem"
          @click="isExpand = !isExpand"
        />

        <FeatherIcon type="x" size="18" @click="closeExportCard" />
      </div>
    </div>
    <div v-if="isExpand" class="content-list">
      <div v-for="item in exportData" :key="item._id" class="content">
        <div class="name-block">
          <img v-if="item.file_type.toLowerCase() === 'pdf'" :src="require('@/assets/images/export/pdf-file.svg')" />
          <img v-if="item.file_type.toLowerCase() === 'csv'" :src="require('@/assets/images/export/csv-file.svg')" />
          <img v-if="item.file_type.toLowerCase() === 'json'" :src="require('@/assets/images/export/json-file.svg')" />
          <div class="file-name">{{ item.file_name }}</div>
        </div>

        <div class="action-block">
          <div
            v-if="item.exported_status === 'new' || item.exported_status === 'generating'"
            class="action-generating"
            :disabled="item.exported_status === 'new' || item.exported_status === 'generating'"
          >
            <a-spin :indicator="indicator" />
            <span class="action-text"> Generating...</span>
          </div>

          <div v-if="item.exported_status === 'generated'" class="action-download">
            <a-button type="primary" size="small" @click="downloadFile(item)">Download</a-button>
          </div>

          <div v-if="item.exported_status === 'downloaded'" class="action-download-again">
            <div class="download-again" @click="downloadFile(item)">
              <img :src="require('@/assets/images/export/sync.svg')" />
              <span class="action-text">Download again</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, h, toRefs } from 'vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import api from '@/services/api';

export default {
  name: 'ExportCard',
  components: {},
  emits: ['closeExportCard', 'toggleDownload'],
  props: {
    isGenerating: {
      type: Boolean,
      default: false,
    },
    exportData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { isGenerating } = toRefs(props);
    let isExpand = ref(true);

    const indicator = h(LoadingOutlined, {
      spin: true,
    });

    const closeExportCard = () => {
      emit('closeExportCard');
    };

    const getDesc = computed(() => {
      // if (exportRecords.value) {
      //   const data = exportRecords.value.slice(0, 3);
      //   const generatingData = data.filter((d) => d.exported_status === 'new' || d.exported_status === 'generating');
      //   if (generatingData && generatingData.length > 0) {
      //     return 'Generating ' + generatingData.length + ' item...';
      //   }

      //   if (isGenerating.value) {
      //     return 'Generating...';
      //   }
      //   return 'Ready to download';
      // }

      // if (isGenerating.value) {
      //   return 'Generating...';
      // }
      // return 'Ready to download';
      if (isGenerating.value) {
        return 'Generating 1 item...';
      }
      return 'Ready to download';
    });

    const downloadFile = async (item) => {
      const { _id, exported_status, account } = item;
      if (exported_status === 'generated') {
        await api.setDownloadedStatus(_id).catch(() => {});
      }
      window.open(`/services/export-download?id=${_id}&account=${account}`, '_blank');
      emit('toggleDownload');
    };

    return {
      isExpand,
      closeExportCard,
      getDesc,
      downloadFile,
      indicator,
    };
  },
};
</script>

<style lang="scss">
.card-export {
  width: 431px;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
  // height: 348px;
  background: #ffffff;
  border: 1px solid #f1f2f6;
  box-sizing: border-box;
  box-shadow: 0px 5px 30px rgba(146, 153, 184, 0.194);
  border-radius: 15px 15px 0px 0px;
  margin-left: 67% !important;
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 1;
  z-index: 100;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    // width: 431px;
    height: 88px;
    box-shadow: 0px 5px 30px rgba(146, 153, 184, 0.194);
    // border-radius: 15px 15px 0px 0px;
    .header-left {
      display: flex;

      .title-block {
        margin-left: 1rem;
        display: flex;
        flex-flow: column;
        justify-content: center;

        .title {
          font-size: 16px;
          font-weight: 500;
          color: #272b41;
        }

        .desc {
          font-size: 14px;
          color: #9299b8;
        }
      }
    }

    .header-right {
      i {
        color: #9299b8;
      }
    }
  }

  .content-list {
    padding: 0 25px 25px 25px;
    width: 431px;
    height: 220px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media screen and (max-width: 575px) {
      padding: 0 20px;
      width: 100%;
    }

    .content {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name-block {
        img {
          width: 20px;
        }
        display: flex;
        align-items: center;
        .file-name {
          color: #5a5f7d;
          margin-left: 1rem;
        }
      }

      .action-block {
        .action-text {
          color: #097cff;
          font-size: 13px;
          font-weight: 500;
          margin-left: 0.5rem;
        }

        .action-download-again {
          cursor: pointer;
        }
      }
    }
    .content:not(:last-child) {
      border-bottom: 1px solid #f1f2f6;
    }
  }
}

@media screen and (min-width: 575px) {
  .card-export-close {
    bottom: 20px;
    border-radius: 15px;
  }
}

// .card-export-close {
//   bottom: 20px;
//   border-radius: 15px;
// }
</style>
