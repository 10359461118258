<template>
  <div class="explore-page">
    <CustomPageHeader
      title="Explore"
      :hasBottomSubtitle="true"
      :subtitle="subTitle"
      :hasExport="hasExport"
      :disabledExport="isGenerating"
      :maxWidth="true"
      @toggleExport="toggleExport"
    >
      <template #subtitle-actions-left>
        <BulkActions
          :key="refresh"
          v-model="selectAll"
          :indeterminate="indeterminate"
          :selectedCount="selectedCount"
          @change="onSelectedChange"
          @onMarkAsRead="confirmMarkAsRead"
          @onSetSentiment="confirmSetSentiment"
          @onReject="confirmReject"
        ></BulkActions>
      </template>
      <template #subtitle-actions>
        <div id="alert-review-selector" class="flex-align-center">
          <div class="pale-gray mr-2">Show:</div>
          <a-select
            ref="alert-review"
            v-model:value="filterShow"
            :getPopupContainer="(trigger) => trigger.parentNode"
            style="width: 135px"
            @change="onFilterShowChange"
          >
            <a-select-option value="">All messages</a-select-option>
            <a-select-option v-if="isDomAdmin" value="reviewed"
              >Reviewed</a-select-option
            >
            <a-select-option v-if="isDomAdmin" value="notreviewed"
              >Not Reviewed</a-select-option
            >
            <a-select-option v-if="isDomAdmin" value="alerted"
              >Alerted</a-select-option
            >
            <a-select-option v-if="isDomAdmin" value="notalerted"
              >Not Alerted</a-select-option
            >
            <a-select-option value="read">Already Read</a-select-option>
            <a-select-option value="unread">Unread</a-select-option>
          </a-select>
        </div>
        <SortByButton
          v-if="!isMobileScreen"
          class="ml-2"
          :default-sort="overrideCriteria.sort.field"
          :default-direction="overrideCriteria.sort.direction"
          @change="onSortChange"
        />
      </template>
      <template #subtitle-actions-mobile>
        <div class="mt-2">
          <SortByButton
            :hide-title="true"
            :default-sort="overrideCriteria.sort.field"
            :default-direction="overrideCriteria.sort.direction"
            @change="onSortChange"
          />
        </div>
      </template>
    </CustomPageHeader>
    <div class="page-content" @scroll="onScroll">
      <MessageAlertNewSetup></MessageAlertNewSetup>
      <div v-if="!isInit || isLoading" class="text-center">
        <a-spin />
      </div>
      <NoDataFound
        v-else-if="isInit && listResult.data && listResult.data.length === 0"
      />
      <template
        v-else-if="isInit && listResult.data && listResult.data.length > 0"
      >
        <a-checkbox-group v-model:value="selectedMessages" class="message-card">
          <TransitionGroup name="messages-transition">
            <a-card
              v-for="item in listResult.data"
              :key="item.info.id"
              :class="{
                'selected-card': selectedMessages.includes(item.info.id),
              }"
            >
              <div class="list-wrapper">
                <MessageItem
                  class="list-item"
                  :item="item"
                  :show-category="true"
                  :show-long-text="true"
                  :show-comment-tag="true"
                  :has-thumb="true"
                  :use-lazy="true"
                  :parentName="'explore'"
                  @click="drilldown(item)"
                >
                  <template #header-prefix>
                    <a-checkbox :value="item.info.id" @click.stop></a-checkbox>
                  </template>
                </MessageItem>
              </div>
            </a-card>
          </TransitionGroup>
        </a-checkbox-group>
        <div class="text-center">
          <a-pagination
            v-model:current="overrideCriteria.paging.page"
            v-model:pageSize="overrideCriteria.paging.recordPerPage"
            show-quick-jumper
            show-size-changer
            :default-current="1"
            :total="listResult.count"
            :defaultPageSize="20"
            :pageSizeOptions="['20', '50', '100']"
            @showSizeChange="onPageSizeChange"
            @change="onPageChange"
          />
        </div>
      </template>
    </div>
    <ExportCard
      v-if="isOpenExportCard"
      :isGenerating="isGenerating"
      :exportData="exportData"
      @toggleDownload="getExportStatus"
      @closeExportCard="closeExportCard"
    ></ExportCard>
    <!-- <SeeMoreDialog /> -->
  </div>
</template>

<script>
import {
  computed,
  reactive,
  watch,
  onMounted,
  onBeforeMount,
  provide,
  ref,
  onBeforeUnmount,
  onUnmounted,
  h,
  createVNode,
} from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { notification, Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

import { useRoute } from 'vue-router';

import api from '@/services/api';
import helper from '@/services/helper';

import SortByButton from '@/components/Button/SortByButton.vue';
import MessageItem from '@/components/Message/MessageItem.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import ExportCard from '@/views/Settings/ExportCard.vue';
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import BulkActions from '@/views/Explore/BulkActions.vue';
import MessageAlertNewSetup from '@/components/Message/MessageAlertNewSetup.vue';

export default {
  name: 'Explore',
  components: {
    SortByButton,
    MessageItem,
    NoDataFound,
    ExportCard,
    CustomPageHeader,
    // SeeMoreDialog,
    BulkActions,
    MessageAlertNewSetup,
  },
  setup() {
    const isInit = ref(false);
    const isLoading = ref(false);
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const route = useRoute();
    const userInfo = computed(() => store.getters['account/userInfo']);
    const isDomAdmin = computed(() => store.getters['account/isDomAdmin']);
    const filterCriteria = computed(
      () => store.getters['filter/filterCriteria']
    );
    const sourceNameList = computed(
      () => store.getters['account/sourceNameList']
    );
    const overrideCriteria = reactive({
      paging: {
        recordPerPage: 100,
        page: 1,
      },
      sort: {
        field: 'engagement_score',
        direction: 'desc',
      },
      highlight: {
        enable: true,
      },
      //
      // source: ['facebook'],
      // contentType: ['video'],
    });

    const indeterminate = ref(false);
    const selectedMessages = ref([]);
    const refresh = ref(0);
    const selectAll = ref(true);
    const showCriteria = ref({});
    const filterShow = ref('');
    const listResult = reactive({
      count: 0,
      total: 0,
      time: 0,
      data: [],
    });

    const selectedCount = computed(() => {
      return selectedMessages.value.length;
    });

    const optionIds = computed(() => {
      return listResult.data.map((v) => v.info.id);
    });

    const rawStoreSort = localStorage.getItem('stored-sort');
    if (rawStoreSort) {
      const storedSort = JSON.parse(rawStoreSort);
      overrideCriteria.sort = storedSort;
    }

    const rawStoreShow = localStorage.getItem('stored-show');
    if (rawStoreShow) {
      filterShow.value = rawStoreShow;

      if (filterShow.value === '') {
        showCriteria.value = {};
      } else if (filterShow.value === 'alerted') {
        showCriteria.value = {
          alerted: true,
        };
      } else if (filterShow.value === 'notalerted') {
        showCriteria.value = {
          alerted: false,
        };
      } else if (filterShow.value === 'reviewed') {
        showCriteria.value = {
          reviewed: true,
        };
      } else if (filterShow.value === 'notreviewed') {
        showCriteria.value = {
          reviewed: false,
        };
      } else if (filterShow.value === 'read') {
        showCriteria.value = {
          read: true,
        };
      } else if (filterShow.value === 'unread') {
        showCriteria.value = {
          read: false,
        };
      }
    }

    const subCategoryName = ref({});

    const subTitle = computed(
      () =>
        'Showing ' +
        helper.numeral(listResult.total) +
        ' results (' +
        listResult.time +
        ' seconds)'
    );

    const drilldown = (item) => {
      store.dispatch('seemore/show', item);
    };

    const init = async () => {
      isLoading.value = true;
      const t0 = performance.now();
      if (localStorage.getItem('stored-paging')) {
        overrideCriteria.paging.recordPerPage =
          localStorage.getItem('stored-paging');
      }
      const finalCriteria = {
        ...filterCriteria.value,
        ...overrideCriteria,
        ...showCriteria.value,
      };
      console.log('EXPLORE INIT', finalCriteria);
      const result = await api.getSearchData(finalCriteria).catch(() => {
        notification.error({
          message: 'Error',
          description: 'Cannot search data.',
        });
      });
      if (result && result.message && result.message.data) {
        const highlightResult = await store.dispatch(
          'config/highlightKeywords',
          {
            messageList: result.message.data,
            truncateAt: 0,
          }
        );
        listResult.data = highlightResult;
        subCategoryName.value = result.message.subCategoryName;
        listResult.total = result.message.totalRecord;
        if (result.message.totalRecord > 10000) {
          listResult.count = 10000;
        } else {
          listResult.count = result.message.totalRecord;
        }
      }
      listResult.time = ((performance.now() - t0) / 10000).toFixed(3);
      isInit.value = true;
      isLoading.value = false;
      selectedMessages.value = [];
    };

    const scrollTop = () => {
      document.querySelector('.page-content').scrollTo(0, 0);
    };

    const onPageChange = (pageNumber, pageSize) => {
      refresh.value += 1;
      scrollTop();
      overrideCriteria.paging.recordPerPage = pageSize;
      overrideCriteria.paging.page = pageNumber;
      init();
    };

    const onPageSizeChange = (current, pageSize) => {
      localStorage.setItem('stored-paging', pageSize);
      refresh.value += 1;
      scrollTop();
      overrideCriteria.paging.recordPerPage = pageSize;
      init();
    };

    const onDateChange = (dateRange) => {
      overrideCriteria.time.sinceDate = moment(
        dateRange.fromDate,
        'x'
      ).toISOString();
      overrideCriteria.time.untilDate = moment(
        dateRange.untilDate,
        'x'
      ).toISOString();
      init();
    };

    const onSortChange = (obj) => {
      overrideCriteria.sort = {
        direction: obj.direction,
        field: obj.type,
      };
      localStorage.setItem(
        'stored-sort',
        JSON.stringify(overrideCriteria.sort)
      );
      init();
    };

    const onFilterShowChange = () => {
      if (filterShow.value === '') {
        showCriteria.value = {};
      } else if (filterShow.value === 'alerted') {
        showCriteria.value = {
          alerted: true,
        };
      } else if (filterShow.value === 'notalerted') {
        showCriteria.value = {
          alerted: false,
        };
      } else if (filterShow.value === 'reviewed') {
        showCriteria.value = {
          reviewed: true,
        };
      } else if (filterShow.value === 'notreviewed') {
        showCriteria.value = {
          reviewed: false,
        };
      } else if (filterShow.value === 'read') {
        showCriteria.value = {
          read: true,
        };
      } else if (filterShow.value === 'unread') {
        showCriteria.value = {
          read: false,
        };
      }
      if (filterShow.value) {
        localStorage.setItem('stored-show', filterShow.value);
      } else {
        localStorage.removeItem('stored-show');
      }
      init();
    };

    watch(
      () => filterCriteria.value,
      () => {
        // reset header search
        overrideCriteria.paging.page = 1;
        init();
      }
    );

    onBeforeMount(() => {
      store.dispatch('message/getStoreDateFilter');

      // from overview page (view all top post)
      if (route.params.sortBy) {
        overrideCriteria.sort = {
          field: route.params.sortBy,
          direction: 'desc',
        };
      }
    });

    onMounted(async () => {
      // check file is generating > disable export file button
      getExportStatus();
      activeInterval.value = setInterval(() => {
        getExportStatus();
      }, 5000);

      await store.dispatch('config/getAccountKeyword');
      init();
    });

    const editMessage = (dataChange) => {
      helper.saveLocalMessage(dataChange, listResult.data);
    };

    provide('onEditMessage', editMessage);

    provide('subCategoryName', subCategoryName);
    provide('showMode', filterShow);

    // Export
    let isOpenExportCard = ref(false);
    const toggleExport = (fileType) => {
      exportFile(fileType);
      isOpenExportCard.value = true;
    };

    const closeExportCard = () => {
      isOpenExportCard.value = false;
    };

    let generating = ref(false);

    const exportFile = async (fileType) => {
      generating.value = true;

      if (
        filterCriteria.value &&
        filterCriteria.value.source &&
        filterCriteria.value.source.length <= 0
      ) {
        filterCriteria.value.source = [...sourceNameList.value];
      }

      if (fileType.version === 'report3.2') {
        await api
          .newExport32(filterCriteria.value, fileType.type, null)
          .catch(() => {});
      } else {
        await api
          .newExport(filterCriteria.value, fileType.type, null)
          .catch(() => {});
      }

      getExportStatus();
      activeInterval.value = setInterval(() => {
        getExportStatus();
      }, 5000);
    };

    let activeInterval = ref();
    onBeforeUnmount(() => {
      if (activeInterval.value) {
        clearInterval(activeInterval.value);
      }
      store.dispatch('seemore/close');
    });
    onUnmounted(() => {
      clearInterval(activeInterval.value);
    });

    // export card mini
    const userName = computed(() => store.getters['account/userName']);
    let exportHistory = ref([]);

    const getExportStatus = async () => {
      const result = await api
        .getExportStatus(userName.value, 1)
        .catch(() => {});
      if (result && result.message) {
        const { records } = result.message;
        exportHistory.value = records;

        const loading = exportHistory.value.some(
          (ele) =>
            ele.exported_status === 'new' ||
            ele.exported_status === 'generating'
        );

        if (!loading) {
          generating.value = false;
          if (activeInterval.value) {
            clearInterval(activeInterval.value);
          }
        } else {
          generating.value = true;
        }
      }
    };

    const exportData = computed(() => {
      // if (exportHistory.value && exportHistory.value.length > 0) {
      //   return exportHistory.value;
      // }
      // return [];
      return exportHistory.value;
    });

    // if file is downloading > disable button
    const isGenerating = computed(() => {
      return generating.value;
    });

    // hide popover when scrolling
    const onScroll = () => {
      const scrollElement = document.getElementsByClassName('ant-popover');

      if (scrollElement) {
        for (let ele of scrollElement) {
          ele.style.display = 'none';
        }
      }
    };

    const hasExport = computed(() => {
      if (userInfo.value && userInfo.value.id === '61e53d2e08f1d127489a051d') {
        return false;
      }
      return true;
    });

    const confirmIcon = (type) => {
      let color = '#3371FF';
      if (type === 'reject') {
        color = '#FF4D4F';
      }
      return createVNode(ExclamationCircleOutlined, {
        style: `color:${color}`,
      });
    };

    const confirmAction = (title, message, actionFunction) => {
      Modal.confirm({
        icon: confirmIcon,
        title: title,
        content: h('div', {}, [h('span', message)]),
        onOk() {
          return actionFunction();
        },
      });
    };

    const getMessageText = (count) => {
      return count > 1 ? `${count} messages` : 'Message';
    };

    const confirmMarkAsRead = () => {
      const pluralize = selectedCount.value > 1 ? 's' : '';
      const contentText = `Would you like to mark these ${selectedCount.value} message${pluralize} as read?`;

      confirmAction('Confirmation', contentText, () => {
        const updateParams =
          filterShow.value === 'unread' ? { removed: true } : { read: true };

        return api
          .bulkMarkAsRead(selectedMessages.value)
          .then((res) => {
            let messageCount = res.message.listIdSuccess.length;
            notification.success({
              message: `${getMessageText(
                messageCount
              )} marked as read successfully.`,
            });
            messageCount = res.message.listIdFail.length;
            if (messageCount > 0) {
              notification.error({
                message: `${getMessageText(
                  messageCount
                )} marked as read error.`,
              });
            }

            res.message.listIdSuccess.forEach((id) => {
              editMessage({ messageId: id, ...updateParams });
            });
          })
          .catch((error) => {
            notification.error({
              message: `${getMessageText(
                selectedCount.value
              )} marked as read error.`,
            });

            console.log('confirmMarkAsRead error:', error);
          })
          .finally(() => {
            selectedMessages.value = [];
            if (filterShow.value === 'unread') {
              init();
            }
          });
      });
    };

    const confirmSetSentiment = (sentiment) => {
      const messageCount = selectedCount.value;
      const capitalizedSentiment = helper.capitalize(sentiment);
      const contentText = `Would you like to set sentiment to ${capitalizedSentiment} ${
        messageCount > 1
          ? `to these ${messageCount} messages`
          : 'to this message'
      }?`;

      confirmAction('Confirmation', contentText, () => {
        let sentimentNumber = 0.0;
        const messageCount = selectedMessages.value.length;
        const message = getMessageText(messageCount);

        switch (capitalizedSentiment) {
          case 'Positive':
            sentimentNumber = 5.0;
            break;
          case 'Neutral':
            sentimentNumber = 0.0;
            break;
          case 'Negative':
            sentimentNumber = -5.0;
            break;
          default:
            notification.error({
              message: `${message} change sentiment to ${capitalizedSentiment} error.`,
            });
            return Promise.resolve();
        }

        return api
          .bulkUpdateSentiment(selectedMessages.value, sentimentNumber)
          .then((res) => {
            let messageCount = res.message.listIdSuccess.length;
            notification.success({
              message: `${getMessageText(
                messageCount
              )} set sentiment to ${capitalizedSentiment} successfully.`,
            });

            messageCount = res.message.listIdFail.length;
            if (messageCount > 0) {
              notification.error({
                message: `${getMessageText(
                  messageCount
                )} change sentiment to ${capitalizedSentiment} error.`,
              });
            }

            res.message.listIdSuccess.forEach((id) => {
              editMessage({ messageId: id, sentiment: sentimentNumber });
            });
          })
          .catch((error) => {
            notification.error({
              message: `${message} change sentiment to ${capitalizedSentiment} error.`,
            });
            console.log('confirmSetSentiment error:', error);
          })
          .finally(() => {
            selectedMessages.value = [];
            const listFilterSentiment = filterCriteria.value['sentiment'];
            const isIncluded = listFilterSentiment.some(
              (sentiment) =>
                sentiment.toLowerCase() === capitalizedSentiment.toLowerCase()
            );
            if (!isIncluded) {
              init();
            }
          });
      });
    };

    const confirmReject = () => {
      const pluralize = selectedCount.value > 1 ? 's' : '';
      const contentText = `Would you like to reject these ${selectedCount.value} message${pluralize}?`;

      confirmAction('Confirm Reject', contentText, () => {
        return api
          .bulkRejectMessage(selectedMessages.value)
          .then((res) => {
            let messageCount = res.message.listIdSuccess.length;
            notification.success({
              message: `${getMessageText(messageCount)} rejected successfully.`,
            });

            messageCount = res.message.listIdFail.length;
            if (messageCount > 0) {
              notification.error({
                message: `${getMessageText(messageCount)} rejected error.`,
              });
            }

            res.message.listIdSuccess.forEach((id) => {
              editMessage({ messageId: id, removed: true });
            });
          })
          .catch((error) => {
            const message = getMessageText(selectedCount.value);
            notification.error({ message: `${message} rejected error.` });

            console.log('confirmReject error:', error);
          })
          .finally(() => {
            selectedMessages.value = [];
            init();
          });
      });
    };

    watch(selectedMessages, (newV) => {
      if (newV.length === optionIds.value.length) {
        selectAll.value = true;
      } else {
        selectAll.value = false;
      }

      indeterminate.value =
        !!newV.length && newV.length < optionIds.value.length;
    });

    const onSelectedChange = (e) => {
      if (e.target.checked) {
        selectedMessages.value = optionIds.value;
      } else {
        selectedMessages.value = [];
      }
    };

    return {
      isDomAdmin,
      isMobileScreen,
      isInit,
      isLoading,
      listResult,
      filterShow,
      onFilterShowChange,
      subTitle,
      overrideCriteria,
      onSortChange,
      onDateChange,
      onPageChange,
      onPageSizeChange,
      drilldown,
      toggleExport,
      isOpenExportCard,
      closeExportCard,
      isGenerating,
      getExportStatus,
      exportData,
      onScroll,
      hasExport,
      selectedCount,
      selectAll,
      selectedMessages,
      onSelectedChange,
      indeterminate,
      confirmMarkAsRead,
      confirmSetSentiment,
      confirmReject,
      refresh,
    };
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 800px) {
  :deep(.left-actions) {
    margin-left: -0.5rem;
  }
  :deep(.right-actions) {
    margin-right: 0.5rem;
  }
}

.selected-card {
  box-shadow: 0px 0px 8px 0px rgba(173, 198, 255, 1);
  border: 2px solid rgba(173, 198, 255, 1);
  background: rgba(245, 248, 255, 1);
}
</style>

<style lang="scss">
.explore-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .ant-page-header-heading-sub-title {
    position: absolute;
    top: 65px;
  }

  .page-content {
    padding: 36px;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .message-card {
    margin: 0 auto 24px auto;
    max-width: 800px;
  }
  .list-wrapper {
    margin: 0;
    padding: 0;
    .list-item {
      padding-top: 25px;
    }
    .message-item {
      .content-wrapper {
        word-break: break-word;
      }
    }
  }
}
.messages-transition-enter-active,
.messages-transition-leave-active {
  transition: all 0.5s ease;
}
.messages-transition-enter-from,
.messages-transition-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.fw-500 {
  font-weight: 500;
}
.fs-13 {
  font-size: 0.8125rem;
}
.list-style-inside {
  list-style: inside;
}
</style>
